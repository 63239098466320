@import "~@ui/styles/tools";
@import "~@ui/styles/variables";

.switcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;

  @include media-mobile {
    justify-content: center;
    gap: 0;
  }
}

.mirrored {
  transform: rotate(180deg);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.clickable {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &::after {
    display: block;
    content: ' ';
    width: 32px;
    height: 32px;
    position: absolute;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    z-index: 0;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%) scale(0);
    transition: 0.168s ease-in-out;
  }

  &:hover {
    &::after {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  &:active {
    &::after {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

a + .page-number {
  font-size: 20px;
  color: #888888;
  text-align: center;

  &--active {
    color: $color-primary;
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
    color: #888888;
  }
}