@import "../../styles/tools";

@mixin flex($justifying) {
    flex-wrap: wrap;
    justify-content: $justifying;
    gap: 40px;

    & > * {
        flex: initial;
    }

    @include media-tablet {
        justify-content: space-around;
    }
}

@mixin flex-content($justifying) {
    display: flex;
    flex: 1;
    justify-content: $justifying;
}

.container {
    display: flex;
    justify-content: space-around;
}

.container--start {
    @include flex(flex-start)
}

.container--between {
    @include flex(space-between)
}

@include media-mobile {
    .container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        & > * {
            @include flex-content(flex-start)
        }
    }
}

.container-content--flex-start > * {
    @include flex-content(flex-start)
}


.container-content--center > * {
    @include flex-content(center)
}

.container--divider {
    & > *:not(:last-child) {
        border-right: 1px dashed $color-grey;
    }
    & > *:not(:first-child) {
        padding-left: 32px;
    }
}