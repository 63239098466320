@import "../../../styles/variables.scss";
@import "~@ui/styles/tools";

@mixin breakpoint-1450 {
    @media screen and (max-width: 1450px) {
        @content;
    }
}

.container {
    background-color: $color-page-background !important;
    position: relative;
}

.content {
    width: 800px;
    margin: 0 auto;

    @include media-tablet {
        width: 100%;
        max-width: 800px;
    }
}

.sections__container {
    margin-top: 40px;
}

.content__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 1px;
    margin-bottom: 32px;

    @include media-tablet {
        font-size: 30px;
        line-height: 1.2;
    }

    @include media-mobile {
        font-size: 20px;
        line-height: 22px;
    }
}