@import "~@ui/styles/tools";
@import "src/styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}
.container {
    position: relative;
    overflow-x: hidden;
}

.item-wrapper {
    margin: 0 20px;
}

.slider {
    & > div {
        display: flex;

        &:hover {
            cursor: pointer;
        }
    }
}

@include small-breakpoint {
    .item-wrapper {
        margin: 0 10px;
    }
}

@include media-tablet {
    .container {
        & > .slim {
            & > div > ul {
                margin-left: 20%;
            }
        }
    }
    .item-wrapper {
        margin: 5px;
    }
}

@media only screen and (max-width: 600px){
    .container {
        & > .slim {
            width: max(70%, 282px);
        }
    }
}
