@import "~@ui/styles/tools";

.property-item {
    padding-top: 0;
    padding-bottom: 0;
}

.borrowers-map {
    margin-top: 35px;
    position: relative;
    width: 100%;
    aspect-ratio: 18/10;
}

@include media-mobile {
    .property-item {
        padding-left: 0;
        padding-right: 0;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}