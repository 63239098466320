@import "~@ui/styles/tools";

.table {
    width: 100%;

    .column--center {
        width: 110px;
    }

    th {
        &.column {
            text-align: center;
            padding-bottom: 10px;
            font-size: $font-size--small;
            line-height: $font-size--small * 1.4;
            font-weight: 500;
            color: $color-title-active;
        }

        &.column--center {
            text-align: center;
        }

        &.column--left {
            text-align: left;
        }

        &.column--right {
            text-align: right;
        }
    }

    td {
        padding: 0;

        &.column > div {
            border: 1px solid $color-input-background;
            height: 42px;
            margin-bottom: 8px;
            padding: 10px 15px;
        }

        &.column--left > div {
            border-right: none;
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            padding-left: 30px;
        }

        &.column--center > div {
            display: flex;
            justify-content: center;
            gap: 12px;

            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: $color-title-active;
            text-align: center;

            border-right: none;
            border-left: none;
        }

        &.column--right > div {
            border-left: none;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            padding-right: 30px;
        }
    }
}

$bar-height: 20px;

.bar {
    padding: 4px 8px;
    transition: all 0.2s ease-in-out;
    height: $bar-height;
    border-radius: 2px;
}

.bar-text {
    height: $bar-height;
    line-height: $bar-height;
    margin-top: -$bar-height;

    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.3px;
    transition: all 0.175s ease-in-out;
}

.bar-text--left {
    text-align: right;
    padding-right: 5px;
    color: $color-success;
}

.bar-text--right {
    text-align: left;
    padding-left: 5px;
    color: $color-error;
}

.bar--right {}

.bar--left {
    text-align: right;
    margin-left: auto;
}

.bar--danger {
    background: rgba($color-error, .2);
}

.bar--success {
    background: rgba($color-success, .2);
}

@include media-mobile {
    .table {
        td {
            &.column--left > div {
                padding-left: 10px;
            }

            &.column--right > div {
                padding-right: 10px;
            }
        }
    }
}