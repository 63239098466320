@import "~@ui/styles/tools";
@import "src/styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.condition {
    padding: 50px;

    display: grid;
    grid-template-areas: 
        "title title title value_description"
        "description description value value";

    grid-template-columns: 1fr 1fr 1fr max-content;
    grid-template-rows: 1fr max-content;
    gap: 8px 16px;
    align-content: space-between;

    background-color: #ffffff;
    border-radius: 24px;

    &:nth-of-type(1) {
        .title {
            max-width: 15ch;
        }

        .description {
            max-width: 18ch;
        }
    }

    &:nth-of-type(2) {
        grid-template-columns: 1fr 1fr max-content max-content;

        .title {
            max-width: 15ch;
        }

        .description {
            max-width: 25ch;
        }
    }

    &:nth-of-type(3) {
        .title {
            max-width: 20ch;
        }

        .description {
            max-width: 19ch;
        }
    }

    &:nth-of-type(4) {
        grid-template-areas: 
        "title title title value_description"
        "description description description value";

        .title {
            max-width: 15ch;
        }

        .description {
            max-width: 30ch;
        }
    }
}

.title-wrapper {
    grid-area: title;
}

.title {
    font-size: 38px;
    line-height: 44px;
    font-weight: 500;
}

.description-wrapper {
    grid-area: description;

    display: flex;
    align-items: flex-end;
}

.description {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: #8c8c8c;
    margin: auto 0 0;
}

.value-description-wrapper {
    grid-area: value_description;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.value-description {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: #8c8c8c;
    margin: 0;

    text-align: right;
}

.value-wrapper {
    grid-area: value;

    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-end;
    white-space: pre-wrap;
}

.value {
    font-size: 64px;
    line-height: 76px;
    font-weight: 400;
    color: $color-primary;
    margin: 0;
}

.image-wrapper {
    position: relative;
    height: 72px;
}

.image {
    width: 166px !important;
    margin-left: auto;
}

@include medium-breakpoint {
    .condition {
        padding: 32px;
        row-gap: 8px;

        &:nth-of-type(n) {
            grid-template-areas: 
            "title title"
            "description value_description"
            "description value";

            grid-template-columns: 1fr max-content;
            grid-template-rows: max-content 1fr min-content;
        }

        &:nth-of-type(2) {
            grid-template-areas: 
            "title title title title"
            "description description description value_description"
            "description description description value";

            grid-template-columns: repeat(3, minmax(12%, 1fr)) max-content;
        }

        &:nth-of-type(4) {
            grid-template-areas: 
            "title title value_description value_description"
            "description description value_description value_description"
            "description description value value";

            grid-template-columns: 1fr 1fr 1fr max-content;
        }
    }

    .title {
        font-size: 32px;
        line-height: 40px;
    }

    .description {
        font-size: 18px;
        line-height: 22px;
    }

    .value-description {
        font-size: 18px;
        line-height: 22px;
    }

    .value {
        font-size: 50px;
        line-height: 60px;
    }

    .image-wrapper {
        height: 60px;
    }

    .image {
        width: 138px !important;
    }
}

@include small-breakpoint {
    .condition {
        padding: 20px;

        &:nth-of-type(2) {
            grid-template-areas: 
            "title title"
            "description value_description"
            "description value";

            grid-template-columns: minmax(48%, 1fr) max-content;
            grid-template-rows: max-content 1fr min-content;
        }

        &:nth-of-type(4) {
            grid-template-areas: 
            "title title title value_description"
            "description description description value_description"
            "description description description value";

            grid-template-columns: 1fr 1fr 1fr minmax(min-content, 112px);
            grid-template-rows: max-content 1fr max-content;
        }
    }

    .title {
        font-size: 26px;
        line-height: 32px;
    }

    .description {
        font-size: 16px;
        line-height: 19px;
    }

    .value-description {
        font-size: 16px;
        line-height: 19px;
    }

    .value {
        font-size: 40px;
        line-height: 48px;
    }

    .image-wrapper {
        height: 48px;
    }

    .image {
        width: 112px !important;
    }
}

@include media-tablet {
    .condition {
        padding: 20px;
        gap: 8px;
        align-content: unset;
        
        &:nth-child(n) {
            grid-template-areas: 
            "title value_description"
            "description value_description"
            "description value";
        
            grid-template-columns: 1fr max-content;
            grid-template-rows: max-content 1fr min-content;
        }

        &:nth-of-type(4) {
            grid-template-columns: 1fr min-content;
        }
    }

    .description-wrapper {
        display: flex;
        align-items: flex-end;
    }

    .description {
        margin: 0;
        overflow-wrap: break-word;
    }

    .title {
        font-size: 20px;
        line-height: 22px;
    }


    .value {
        font-size: 32px;
        line-height: 38px;
    }

    .image-wrapper {
        height: 38px;
    }

    .image {
        width: 89px !important;
    }
}

@include media-mobile {
    .condition {        
        &:nth-child(n) {
            grid-template-areas: 
            "title" 
            "description"
            "value_description" 
            "value";
            grid-template-columns: 1fr;

            .description {
                max-width: none;
            }
        }

        &:nth-of-type(4) {
            grid-template-columns: 1fr;

            .description {
                max-width: none;
            }
        }
        
        .value-description-wrapper,
        .value-wrapper {
            justify-content: flex-start;
        }

        .image-wrapper {
           width: 100%;
        }

        .image {
            margin-left: inherit;
        }
    }    
}