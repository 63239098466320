@import "~@ui/styles/variables";
@import "~@ui/styles/tools";
@import "@app/styles/variables";

.feature-blocks {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @include media-desktop-md {
        flex-direction: column;
    }
}

.feature-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $color-page-background;
    border-radius: 24px;
    padding: 32px;
    min-height: 360px;
    flex: 1;

    @include media-desktop-md {
        min-height: unset;
        gap: 30px;
    }
}

.feature-block--header {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.feature-block--title {
    font-size: 72px;
    font-weight: 500;
    color: $color-title-lighter;

    @include media-desktop-md {
        font-size: 60px;
    }

    @include media-tablet {
        font-size: 44px;
    }

    @include media-mobile {
        font-size: 32px;
    }
}

.feature-block--description {
    font-size: 20px;
    line-height: 1.2;
    color: #1E202199;
    font-weight: 500;
    margin: 0;

    @include media-mobile {
        font-size: 16px;
    }
}

.feature-block--link {
    font-size: 20px;
    font-weight: 500;

    @include media-mobile {
        font-size: 16px;
    }
}
