@import "~@ui/styles/tools";
@import "src/styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.card {
    position: relative;
    padding: 40px;
    border-radius: 24px;

    display: flex;
    flex-direction: row;
    aspect-ratio: 0.783;
    
    &--with-filter::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 29.5%, rgba(0, 0, 0, 0) 100%);
        border-radius: 24px;
        z-index: 1;
    }

    &:nth-of-type(1) {
        .advantage-text {
            max-width: 18ch;
        }
    }
}

.background-image {
    border-radius: 24px;
    pointer-events: none;
}

.icon {
    margin-top: 6px;
    z-index: 2;
}

.text {
    margin-left: 24px;
    max-width: 21ch;
    z-index: 2;

    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    text-transform: uppercase;

    color: #ffffff;

    &.wide {
        max-width: 23ch;
    }
}

@include medium-breakpoint {
    .card {
        padding: 32px;
    }

    .text {
        font-size: 20px;
    }
}

@include small-breakpoint {
    .card {
        padding: 20px;
    }

    .text {
        font-size: 16px;
        line-height: 19px;
        margin-left: 12px;
    }

    .icon {
        width: 12px;
        height: 12px;
        margin-top: 6px;
    }
}