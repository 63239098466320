@import "~@ui/styles/tools";
@import "../../../../styles/variables.scss";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

.card {
    display: flex;
    flex-direction: column;
    gap: 25px;
    background: $color-page-background !important;
    color: $color-title-lighter;
    cursor: pointer;
    height: 100%;
    transition: all 0.5s;

    &:hover {
        box-shadow: 0px 10px 28px 0px rgba(136, 136, 136, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    }
}

.card__long {
    flex-direction: row-reverse;
    align-items: center;
    gap: 100px;

    .card__text {
        flex: 2;
    }

    .card__image-wrapper {
        flex: 3;
    }

    .card__text-title {
        font-size: 48px;
        margin-bottom: 30px;
    }

    .card__text-description {
        font-size: 18px;
    }

    @include medium-breakpoint {
        .card__text-title {
            font-size: 36px;
            margin-bottom: 24px;
        }

        .card__text-description {
            font-size: 16px;
        }
    }

    @include small-breakpoint {
        .card__text-title {
            font-size: 28px;
            margin-bottom: 16px;
        }

        .card__text-description {
            font-size: 14px;
        }
    }
}

.card__image-wrapper {
    position: relative;
    width: 100%;
    display: grid;
    aspect-ratio: 16/9;

    // Override NextJS img styles to keep correct aspect ratio for image
    img {        
        object-fit: cover;
    }
} 

.card__image {
    border-radius: 12px;
}

.card__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 25px;
}

.card__text {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.card__text-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 16px;

    @include medium-breakpoint {
        font-size: 22px;
        line-height: 1.1;
    }
}

.card__text-description {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 20px;

    @include medium-breakpoint {
        font-size: 14px;
    }

    @include media-mobile {
        font-size: 16px
    }
}

.card__text-date {
    color: #A2A8AD;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: auto;
}
