@import "~@ui/styles/tools";
@import "src/styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.feature {
    padding: 4% 8%;
}

.feature-title {
    font-size: 48px;
    font-weight: 600;
    max-width: 18ch;
    margin-bottom: 32px;
}

.conditions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
}

.button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    margin-top: 40px;
}


@include medium-breakpoint {
    .feature-title {
        font-size: 40px;
    }
}

@include small-breakpoint {
    .feature-title {
        font-size: 32px;
    }
}

@include media-tablet {
    .feature {
        padding: 4% 16px;
    }

    .feature-title {
        font-size: 24px;
    }

    .conditions {
        gap: 12px;
    }
}

@include media-tablet-portrait {
    .conditions {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, max-content);
    }

    .button-wrapper {
        margin: 28px 0 14px;
        & * {
            width: 100%;
        }
    }
}

@include media-mobile {
    .feature-title {
        font-size: 26px;
    }
}