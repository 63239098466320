@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1620px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1300px) {
        @content;
    }
}

.section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    @include media-tablet-portrait {
        gap: 16px
    }

    @include media-mobile {
        gap: 8px;
    }
}

.container {
    display: flex;
    gap: 40px;
    min-width: 35%;
    flex: 1;
    justify-content: space-between;
    position: relative;

    @include media-tablet {
        flex-direction: column-reverse;
        justify-content: flex-end;
        gap: 4px;
    }

    @include media-mobile {
        min-width: 100%;
    }
}

.container__filled {
    background: #F6F7F8 !important;
}

.container__large {
    flex: 2;

    .image__wrapper {
        flex: 4;
    }

    .text-container, .value {
        flex: 3;
    }

    @include small-breakpoint {
        .text-container, .value, .image__wrapper {
            flex: unset;
        }
    }
}

.text-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 !important;

    @include media-tablet {
        flex: 1;
        justify-content: space-between;
    }
}

.title {
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0;

    @include medium-breakpoint {
        max-width: 100%;
        font-size: 32px;
        line-height: 36px;
    }

    @include media-tablet {
        font-size: 28px;
        line-height: 32px;
    }

    @include media-tablet-portrait {
        font-size: 20px;
        line-height: 22px;
    }
}

.description {
    grid-area: description;
    color: #8C8C8C;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;

    > b {
        color: $color-primary;
        font-weight: 400;
    }

    @include media-mobile {
        font-size: 16px;
        line-height: 19px;
    }
}

.value {
    font-size: 40px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0;
    color: $color-primary;
    text-align: right;
    white-space: nowrap;
    padding: 0 !important;

    @include medium-breakpoint {
        font-size: 32px;
    }

    @include media-tablet {
        font-size: 28px;
        text-align: left;
    }

    @include media-tablet-portrait {
        font-size: 20px;
    }
}


.image__wrapper {
    display: flex;
    justify-content: center;
    align-self: flex-end;
    margin-bottom: -32px;

    @include small-breakpoint {
        display: none;
    }
}

.image {
    position: relative;
    width: 387px;
    height: 190px;

    @include medium-breakpoint {
        width: 240px;
        height: 118px;
    }
}