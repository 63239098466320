@import "~@ui/styles/tools";
@import "src/styles/variables";

$color-description: #64696C;

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

@mixin feature-value {
    font-size: 72px;
    line-height: 1;

    @include media-mobile {
        font-size: 48px;
        font-weight: 600;
    }
}

@mixin feature-description {
    font-size: 20px;
    line-height: 1.4;
    color: $color-gray;
    margin-bottom: 0;
}

.feature {
    padding: 4% 8%;
}

.feature-title {
    font-size: 48px;
    font-weight: 600;
    max-width: 18ch;
    margin-bottom: 32px;
}

.heading {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 40px;
    grid-area: heading;

    @include media-tablet {
        font-size: 20px;
    }
}

.chart {
    min-height: 438px;
    height: 438px;
    flex: 1;
    border-radius: 26px;
    border: 13px solid $color-background;
}

.profitability {
    margin-bottom: 40px;
}

.info-value {
    font-size: 72px;
    line-height: 1;
    font-weight: 400;
}

.profitability__info {
    grid-area: info;
    place-self: end;
}

.profitability__info-value {
    @include feature-value();

    margin-bottom: 16px;
}

.profitability__info-description {
    @include feature-description();
}

.profitability__chart {
    grid-area: 1 / 2 / 3 / 4;
}

.profitability__chart-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 50px;
}

.profitability__description-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-area: 2 / 1 / 3 / 2;
}

.profitability__heading {
    grid-area: 1 / 1 / 2 / 2;
}

.button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

@include medium-breakpoint {
    .feature-title {
        font-size: 40px;
    }
}

@include small-breakpoint {
    .feature-title {
        font-size: 32px;
    }
}

@include media-tablet {
    .feature {
        padding: 4% 16px;
    }
    
    .feature-title {
        font-size: 24px;
    }

    .chart {
        border-width: 8px;
        border-radius: 16px;
        height: 310px;
        min-height: 310px;
    }

    .profitability__chart-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .profitability__description-container {
        margin-top: 50px;
    }

    .heading {
        font-size: 20px;
    }

    .info-value {
        font-size: 40px;
    }

    .profitability__info-description {
        font-size: 16px;
    }
}

@include media-mobile {
    .feature-title {
        font-size: 26px;
    }

    .profitability__description-container {
        margin-top: 25px;
    }

    .heading {
        margin-bottom: 12px;
    }

    .button-wrapper {
        width: 100%;

        & * {
            width: 100%;
        }
    }
}