@import "~@ui/styles/variables";
@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

.main-section {
    width: 100%;
}

.container {
    width: 100%;
}

.title {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.1;

    b, em, strong {
        font-weight: 500;
        color: $color-primary;
    }
}

@include medium-breakpoint {

    .title {
        font-size: 56px;
    }
}

@include small-breakpoint {
    .title {
        font-size: 40px;
    }
}

@include xsmall-breakpoint {
    .title {
        font-size: 28px;
    }
}

@include media-mobile {
    .title {
        font-size: 36px;
    }
}