@import "~@ui/styles/tools";
@import "src/styles/variables";

$color-description: #64696C;

@mixin feature-value {
    font-size: 72px;
    line-height: 1;

    @include media-mobile {
        font-size: 48px;
        font-weight: 600;
    }
}

@mixin feature-description {
    font-size: 20px;
    line-height: 1.4;
    color: $color-gray;
    margin-bottom: 0;
}

.heading {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 40px;
    grid-area: heading;

    @include media-tablet {
        font-size: 20px;
    }
}

.chart {
    min-height: 438px;
    height: 438px;
    flex: 1;
    border-radius: 26px;
    border: 13px solid $color-background;
}

.profitability {
    margin-bottom: 20px;
}

.info-value {
    font-size: 72px;
    line-height: 1;
    font-weight: 400;
}

.profitability__info {
    grid-area: info;
    place-self: end;
}

.profitability__info-value {
    @include feature-value();

    margin-bottom: 16px;
}

.profitability__info-description {
    @include feature-description();
}

.profitability__chart {
    grid-area: 1 / 2 / 3 / 4;
}

.reliability {
    @include media-tablet {
        margin-bottom: 20px;
    }
}

.reliability__info {
    display: flex;
    gap: 30px;
    margin-top: 40px;

    @media (min-width: 1025px) and (max-width: 1200px) {
        flex-direction: column;
        gap: 0;
    }

    @include media-mobile {
        flex-direction: column;
        gap: 8px;
        margin-top: 45px;
    }
}

.reliability__chart-info {
    color: #646566;
    font-size: $font-size--xsmall;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 300;
    letter-spacing: 0.3px;
}

.reliability__info-value {
    @include feature-value();

    margin-bottom: 0;
}

.reliability__chart {
    grid-area: 1 / 2 / 3 / 4;
    height: 382px;
    min-height: 382px;
}

.reliability__info-description {
    @include feature-description();

    max-width: 350px;
}

.profitability__liquidity__info-description {
    @include feature-description();

    max-width: 60%;
    margin-bottom: 20px;

    @include media-mobile {
        max-width: 100%;
    }
}

.profitability__chart-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 50px;
}

.profitability__description-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-area: 2 / 1 / 3 / 2;
}

.profitability__heading {
    grid-area: 1 / 1 / 2 / 2;
}

@include media-tablet {
    .chart {
        border-width: 8px;
        border-radius: 16px;
        height: 310px;
        min-height: 310px;
    }

    .profitability__chart-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .profitability__description-container {
        margin-top: 50px;
    }

    .heading {
        font-size: 20px;
    }

    .info-value {
        font-size: 40px;
    }

    .profitability__info-description {
        font-size: 16px;
    }
}

@include media-mobile {
    .reliability__chart {
        height: 260px;
        min-height: 260px;
    }

    .reliability__chart-info {
        margin-top: 10px;
        font-size: 10px;
    }

    .profitability__description-container {
        margin-top: 25px;
    }

    .heading {
        margin-bottom: 12px;
    }
}