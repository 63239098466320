@import "~@ui/styles/tools";
@import "src/styles/variables.scss";

$liquidity-note-color: #64696C;

.liquidity__dom-table {
    margin-bottom: 20px;
}

.liquidity__speed {
    display: flex;
    gap: 60px;
}

.liquidity__speed__item {
    flex: 1;
}

.liquidity__speed-value {
    font-size: 72px;
    line-height: 1;
    margin-bottom: 10px;
}

.liquidity__speed-value-postfix {
    font-size: 40px;
    font-weight: 500;
    text-transform: uppercase;

    margin-left: 20px;
}

.liquidity__speed-time {
    font-size: 18px;
    color: $liquidity-note-color;
    font-weight: 400;
}

.liquidity__speed-time-postfix {
    margin-left: 5px;
}

.liquidity__note {
    font-size: 18px;
    margin-top: 30px;
    color: $liquidity-note-color;
    font-weight: 300;
}

@include media-tablet  {
    .liquidity__note {
        font-size: 14px;
    }

    .liquidity__count {
        font-size: 46px;
    }

    .liquidity__time {
        font-size: 16px;
    }
}

@include media-mobile {
    .liquidity__speed-value {
        font-size: 40px;
    }

    .liquidity__speed-value-postfix {
        font-size: 32px;
    }

    .liquidity__speed-time {
        font-size: 18px;
    }
}

@include media-mobile-xs {
    .liquidity__speed-value {
        font-size: 26px;
    }

    .liquidity__speed-value-postfix {
        font-size: 22px;
        margin-left: 10px;
    }

    .liquidity__speed-time {
        font-size: 16px;
    }
}