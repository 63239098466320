@import "~@ui/styles/variables";
@import "~@ui/styles/tools";
@import "@app/styles/variables";

.block {
    background: $color-off-white;
    padding-top: 50px;
    @include media-mobile {
        padding: 25px 0 10px;
    }
}

.main {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.header--wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;
    z-index: 1;

    @include media-desktop-md {
        gap: 20px;
    }

    @include media-tablet-portrait {
        position: initial;
    }

    @include media-mobile-xs {
        gap: 10px;
    }
}

.achievements {
    justify-content: center;
    position: relative;
    display: flex;
    z-index: 1;
    margin: 40px 20px;

    @include media-tablet {
        margin: 20px 10px 32px;
    }

    @include media-tablet-portrait {
        display: grid;
        position: relative;
    }
}

.button {
    @include media-tablet {
        padding: 14px 24px !important;
    }

    @include media-mobile {
        font-size: 16px !important;
    }
}

.title {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.1;
    margin: 0;
    white-space: nowrap;

    @include media-desktop-md {
        font-size: 60px;
    }

    @include media-tablet {
        font-size: 44px;
    }

    @include media-mobile {
        font-size: 36px;
    }

    @include media-mobile-xs {
        font-size: 24px;
    }
}

.description {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 1.6;
    margin: 0;
    width: 640px;

    @include media-desktop-md {
        width: 500px;
        font-size: 16px;
    }

    @include media-mobile {
        width: unset;
        right: 0;
        left: 0;
    }

    @include media-mobile-xs {
        font-size: 12px;
    }
}

.content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -180px;

    @include media-desktop-md {
        margin-top: -110px;
    }

    @include media-mobile {
        margin-top: -100px;
    }
}

.image--wrapper {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180.26deg, rgba(247, 247, 247, 0) 75%, #FFFFFF 88%);
    }
}

.image {
    width: 1920px;
    object-fit: cover;
    height: auto;
    margin-right: -85px;

    @include media-desktop-md {
        width: 1640px;
    }

    @include media-tablet {
        width: 1350px;
        margin-right: -60px;
    }

    @include media-mobile-xs {
        width: 1000px;
        margin-right: -20px;
    }
}

.success_companies {
    position: absolute;
    top: 230px;
    left: calc((100vw - 1150px) / 2);
    display: flex;
    flex-direction: column;
    gap: 28px;
    background: $color-off-white;
    padding: 24px;
    width: 300px;
    border-radius: 18px;

    @include media-desktop-md {
        top: 210px;
        left: calc((100vw - 1000px) / 2);
        width: 270px;
        padding: 21.6px;
        gap: 25.2px;
        border-radius: 16px;
    }

    @include media-tablet {
        gap: unset;
        justify-content: space-between;
    }
}

.success_companies--icon {
    width: 36px;
    height: auto;

    @include media-desktop-md {
        width: 32px;
    }
}

.success_companies--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.success_companies--footer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-desktop-md {
        gap: 10.8px
    }
}

.success_companies--title {
    color: $color-title-active;
    font-size: 44px;
    line-height: 1.2;
    margin: 0;
    font-weight: 700;

    @include media-desktop-md {
        font-size: 40px;
    }

    @include media-tablet {
        font-size: 32px;
    }
}

.success_companies--description {
    color: $color-title-active;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    font-weight: 400;

    @include media-desktop-md {
        font-size: 14px;
    }
}

.success_companies--companies {
    width: 65%;
    height: auto;

    @include media-tablet {
        width: 165px;
    }
}

.cagr {
    position: absolute;
    top: 300px;
    right: calc((100vw - 1150px) / 2);
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: $color-off-white;
    padding: 24px 24px 96px;
    width: 300px;
    border-radius: 18px;
    overflow: hidden;

    @include media-desktop-md {
        top: 300px;
        right: calc((100vw - 1050px) / 2);
        width: 270px;
        padding: 21.6px 21.6px 85.6px;
        border-radius: 16px;
        gap: 10.8px;
    }
}

.cagr--icon {
    width: 36px;
    height: auto;

    @include media-desktop-md {
        width: 32px;
    }
}

.cagr--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cagr--title {
    color: $color-title-active;
    font-size: 44px;
    line-height: 1.2;
    margin: 0;
    font-weight: 700;

    @include media-desktop-md {
        font-size: 40px;
    }

    @include media-tablet {
        font-size: 32px;
    }
}

.cagr--description {
    color: $color-title-active;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    font-weight: 400;

    @include media-desktop-md {
        font-size: 14px;
    }
}

.cagr--background {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;

    @include media-desktop-md {
        bottom: 14px;
    }

    @include media-tablet {
        width: 115%;
        height: 100px;
    }

    @include media-tablet-portrait {
        width: 100%;
    }
}

.active_investors {
    position: absolute;
    top: 675px;
    left: calc((100vw - 990px) / 2);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: $color-off-white;
    padding: 24px 0;
    width: 250px;
    border-radius: 18px;

    @include media-desktop-md {
        top: 580px;
        left: calc((100vw - 920px) / 2);
        width: 220px;
        gap: 18px;
        padding: 21.6px 0;
        border-radius: 16px;
    }

    @include media-tablet {
        align-items: flex-start;
        gap: unset;
        justify-content: space-between;
    }

    @include media-tablet-portrait {
        flex-direction: row-reverse;
        align-items: center;
        height: auto !important;
    }
}

.active_investors--investor {
    width: 100px;
    height: auto;

    @include media-desktop-md {
        width: 90px;
    }

    @include media-tablet {
        width: 60px;
    }
}

.active_investors--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    @include media-desktop-md {
        gap: 10.8px;
    }

    @include media-tablet {
        align-items: flex-start;
        gap: 8px;
    }
}

.active_investors--title {
    color: $color-title-lighter;
    font-size: 44px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    margin: 0;

    @include media-desktop-md {
        font-size: 40px;
    }

    @include media-tablet {
        font-size: 32px;
        text-align: left;
    }
}

.active_investors--description {
    color: $color-title-lighter;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    margin: 0;

    @include media-desktop-md {
        font-size: 14px;
    }

    @include media-tablet {
        text-align: left;
    }
}

.block--case-icon {
    position: absolute;
    width: 70px;
    height: auto;
    top: 530px;
    left: calc((100vw - 965px) / 2);

    @include media-desktop-md {
        width: 63px;
        top: 460px;
        left: calc((100vw - 820px) / 2);
    }

    @include media-tablet {
        width: 80px;
        top: 520px;
        left: calc((100vw - 640px) / 2);
    }

    @include media-mobile {
        display: none;
    }
}

.block--avatar-icon {
    position: absolute;
    width: 65px;
    height: auto;
    top: 670px;
    right: calc((100vw - 900px) / 2);

    @include media-desktop-md {
        width: 58px;
        top: 580px;
        right: calc((100vw - 790px) / 2);
    }

    @include media-tablet {
        top: 290px;
        right: calc((100vw - 540px) / 2);
    }

    @include media-mobile {
        display: none;
    }
}

.block--person-icon {
    display: none;
    width: 90px;
    height: auto;

    @include media-tablet {
        display: block;
        position: absolute;
        top: 240px;
        left: calc((100vw - 610px) / 2);
    }

    @include media-mobile {
        display: none;
    }
}

.cards {
    @include media-tablet {
        width: 100%;
        margin-top: -60px;
        display: flex;
        gap: 12px;
        padding: 20px 0;
    }

    @include media-tablet-portrait {
        flex-direction: column;
        margin-top: -50px;
    }

    @include media-mobile-xs {
        margin-top: -20px;
    }
}

.success_companies, .cagr, .active_investors {

    @include media-tablet {
        top: unset;
        right: unset;
        left: unset;
        bottom: unset;
        position: relative;
        width: 100%;
        flex: 1;
        box-shadow: 0 3.6px 18px 0 #B6B6B640;
        padding: 20px;
        height: 200px;
    }

    @include media-tablet-portrait {
        flex: unset;
    }
}
