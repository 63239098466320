@import '~@ui/styles/tools';

.section {
    padding: 4% 8%;

    @include media-tablet {
        padding: 4% 16px;
    }
}

.section__filled {
    background: $color-off-white;
}

.container {
    display: flex;
    gap: 24px;

    @include media-tablet-portrait {
        flex-direction: column;
        width: 100%;
    }
}

