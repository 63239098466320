@import "~@ui/styles/tools";
@import "../../../../styles/variables";

.container {
    display: flex;
    flex-direction: column;
    height: 100%;

    p {
        padding: 0;
        margin: 0;
    }
}

.img {
    position: relative;
    background-size: 100%;
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 12px 12px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    img {
        -webkit-user-drag: none;
    }

    .preview {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: all 400ms ease-in-out;
    }

    .icon {
        z-index: 1;
    }
}

.content {
    padding: 28px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .top-block {
        display: flex;
        gap: 14px;
        flex-direction: column;
    }

    .date {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $color-gray;
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: $color-title-lighter;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

.bottom-block {
    display: flex;
    align-items: center;
    margin-top: 20px;

    img {
        border-radius: 50%;
        margin-right: 12px;
        -webkit-user-drag: none;
    }

    .name {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $color-title-lighter;
        margin-right: 8px;
    }
}

.container:hover {
    .preview {
        width: 115%;
        height: 115%;
    }
}

@include media-mobile {
    .content {
        padding: 20px;

        .title {
            font-size: 14px;
            line-height: 20px;
        }

        .date {
            font-size: 12px;
        }

        .top-block {
            gap: 8px;
        }
    }

    .bottom-block {
        margin-top: 20px;
        .name {
            font-size: 12px;
        }
    }

    .img {
        aspect-ratio: 16/9;
        border-radius: 20px 20px 5px 5px;
    }

    .author-icon {
        width: 32px;
        height: 32px;
    }
}