@import "~@ui/styles/variables";
@import "~@ui/styles/tools";
@import "@app/styles/variables";

.block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 4% 8% 6%;
    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        filter: blur(50px); // Пример размытия, можно изменить
    }

    &::before {
        background: #B5E9FFE5;
        width: 850px;
        height: 650px;
        top: 200px;
        right: max(-150px, calc(100vw - 1870px));
    }

    @include media-tablet {
        padding: 4% 16px 6%;
    }
}

.block--content {
    display: flex;
    flex-direction: column;
    gap: 60px;

    @include media-tablet {
        gap: 40px;
    }

    @include media-mobile {
        width: 100%;
    }
}

.block--title {
    color: $color-title-lighter;
    font-size: 48px;
    font-weight: 600;
    max-width: 18ch;
    margin: 0;

    @include media-tablet {
        font-size: 32px;
    }
}

.block--icon {
    width: auto;
    height: 550px;

    @include media-desktop-md {
        height: 450px;
    }

    @include media-tablet {
        height: 350px;
    }

    @include media-tablet-portrait {
        height: 250px;
    }

    @include media-mobile {
        display: none;
    }
}

.block--ellipse {
    position: absolute;
    z-index: -1;
    right: min(-200px, calc(100vw - 2120px));
    top: 200px;
    bottom: 0;
    width: 1058px;
    height: 1058px;
    border: 4px #065CF512 solid;
    border-radius: 50%;

    &:last-of-type {
        top: 300px;
        right: min(-500px, calc(100vw - 2420px));
    }


    @include media-desktop-md {
        right: min(-200px, calc(100vw - 1640px));


        &:last-of-type {
            right: min(-500px, calc(100vw - 1940px));
        }
    }

    @include media-tablet {
        right: min(-200px, calc(100vw - 1440px));


        &:last-of-type {
            right: min(-500px, calc(100vw - 1540px));
        }
    }

    @include media-tablet-portrait {
        display: none;
    }
}

.card {
    display: flex;
    flex-direction: column;
    background: $color-off-white;
    gap: 32px;
    padding: 32px;
    border-radius: 24px;
    width: 650px;

    @include media-desktop-md {
        width: fit-content;
    }

    @include media-tablet-portrait {
        padding: 20px;
        gap: 16px;
    }

    @include media-mobile {
        width: 100%;
    }
}

.card--header {
    display: flex;
    align-items: center;
    gap: 32px;
}

.card--avatar {
    border-radius: 50%;
    width: 80px;
    height: auto;
}

.card--info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.card--name {
    color: $color-title-lighter;
    font-size: 28px;
    line-height: 1.4;
    font-weight: 600;

    @include media-tablet-portrait {
        font-size: 24px;
    }
}

.card--position {
    color: #1E202166;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0;

    @include media-tablet-portrait {
        font-size: 16px;
    }
}

.card--credit {
    display: flex;
    gap: 60px;

    @include media-mobile {
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-between;
        max-width: 350px;
    }
}

.card--credit-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    white-space: nowrap;
}

.card--credit-title {
    color: #1E202166;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    letter-spacing: 0.6px;
    margin: 0;
}

.card--credit-value {
    color: $color-title-lighter;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.6px;

    @include media-tablet-portrait {
        font-size: 16px;
    }
}

.card--hr {
    background: #DADADA;
    width: 100%;
}

.card--social {
    display: flex;
    gap: 32px;

    @include media-tablet-portrait {
        gap: 20px;
    }
}

.card--social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: transparent;
    transition: .5s;
    padding: 10px;
    margin: -10px;

    &:hover {
        background: rgba(0, 0, 0, 0.1)
    }
}

.card--social-icon {
    width: 50px;
    height: auto;

    @include media-tablet-portrait {
        width: 32px;
    }
}
