@import "~@ui/styles/tools";
@import "src/styles/variables.scss";

.content {
    display: flex;
    gap: 24px;
    height: 432px;

    @include media-tablet {
        height: 393px;
        gap: 16px;
    }

    @include media-tablet-portrait {
        flex-direction: column;
        height: auto;
    }
}

.card {
    position: relative;
    flex: 1;
    border-radius: 12px;
    overflow: hidden;
    height: 100%;

    &__content {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
        padding: 32px;
        z-index: 1;

        @include media-tablet-portrait {
            flex-direction: row;
            align-items: center;
        }

        @include media-mobile {
            flex-direction: column;
            align-items: unset;
            gap: 24px;
        }
    }

    &__image {
        object-fit: cover;
    }

    &__title {
        font-size: 40px;
        line-height: 1.2;
        font-weight: 500;
        color: $color-off-white;

        @include media-table-large {
            font-size: 32px;
        }
    }

    &__price-container {
        background: #1E6353;
        padding: 24px;
        border-radius: 12px;
        width: fit-content;
        height: fit-content;
    }

    &__price {
        font-size: 52px;
        line-height: 1.2;
        font-weight: 500;
        color: $color-off-white;
        white-space: nowrap;

        @include media-table-large {
            font-size: 48px;
        }

        @include media-tablet {
            font-size: 32px;
        }
    }
}

.reports {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
    overflow: auto;
    padding-right: 6px;

    @include media-tablet-portrait {
        max-height: 348px;
        flex: unset;
    }

    &__row {
        overflow: unset;
        flex-direction: row;
        gap: 24px;
        padding: 0;

        @include media-desktop-md {
            gap: 12px;
        }

        @include media-tablet-portrait {
            flex-direction: column;
        }

        .reports__item {
            flex: 1;
            width: auto;
            flex-direction: column;
            align-items: flex-start;
            padding: 28px 24px;
            height: 100%;

            @include media-table-large {
                padding: 20px 16px;
            }

            @include media-tablet-portrait {
                flex: unset;
                width: 100%;
                flex-direction: row;
                align-items: center;
                padding: 16px 24px;
                height: 108px;
            }
        }

        .reports__title {
            font-size: 32px;

            @include media-desktop-md {
                font-size: 24px;
            }

            @include media-tablet {
                font-size: 18px;
            }

            @include media-tablet-portrait {
                font-size: 24px;
            }

            @include media-mobile {
                font-size: 16px;
            }
        }

        .reports__broker {
            flex-direction: column;
            align-items: flex-start;

            @include media-tablet-portrait {
                flex-direction: row;
                align-items: center;
            }
        }

        .reports__price {
            align-items: flex-start;

            @include media-tablet-portrait {
                align-items: flex-end;
            }
        }

        .reports__label {
            @include media-tablet {
                font-size: 14px;
            }
        }

        .reports__value {
            font-size: 40px;

            @include media-desktop-md {
                font-size: 32px;
            }

            @include media-table-large {
                font-size: 24px;
            }

            @include media-tablet {
                font-size: 20px;
            }
        }

        .reports__content {
            align-items: flex-start;

            @include media-tablet {
                gap: 8px;
            }

            @include media-tablet-portrait {
                align-items: flex-end;
            }
        }

        .reports__url {
            @include media-tablet {
                font-size: 16px;
            }
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 24px;
        width: 100%;
        background: $color-page-background;
        padding: 20px 24px;
        border-radius: 12px;
        height: 136px;

        @include media-tablet {
            padding: 16px 20px;
            height: 123px;
        }

        @include media-tablet-portrait {
            height: 108px;
        }
    }

    &__broker {
        display: flex;
        align-items: center;
        gap: 16px;

        @include media-tablet {
            gap: 12px;
        }
    }

    &__icon {
        min-height: 44px;
        min-width: 44px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #E2E3E4;

        > img {
            object-fit: cover;
        }
    }

    &__title {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 500;
        color: $color-title-active;

        @include media-table-large {
            font-size: 22px;
        }

        @include media-tablet {
            font-size: 18px;
        }

        @include media-tablet-portrait {
            font-size: 24px;
        }

        @include media-mobile {
            font-size: 16px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;

        @include media-tablet {
            gap: 12px;
        }

        @include media-tablet-portrait {
            gap: 8px;
        }
    }

    &__price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
    }

    &__label {
        font-size: 16px;
        line-height: 1.2;
        font-weight: 400;
        color: $color-body;

        @include media-table-large {
            font-size: 14px;
        }
    }

    &__value {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 400;
        color: $color-title-active;
        white-space: nowrap;

        @include media-table-large {
            font-size: 22px;
        }

        @include media-tablet {
            font-size: 20px;
        }
    }

    &__url {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 500;
        color: $color-primary;

        @include media-table-large {
            font-size: 18px;
        }


        @include media-tablet  {
            font-size: 16px;
        }
    }
}
