@import "~@ui/styles/tools";
@import "src/styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.recommendations {
    // TODO: refactor (copied from NewsBlogSection styles)
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;

    max-width: 1800px;
    margin: 25px auto 0;
}


@include media-tablet {
    .recommendations {
        grid-template-columns: 1fr 1fr;
    }
}

@include media-mobile {
    .recommendations {
        grid-template-columns: 1fr;
    }
}